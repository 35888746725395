import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { AppBar } from "../components/AppBar";
import { Container, makeStyles, Typography, Button } from "@material-ui/core";
import { Grid } from "@mui/material";
import Footer from "../components/footer/footer";
import pilotImage from "../assets/pilotImage.png";
import CodeIcon from "@mui/icons-material/Code";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import PinDropIcon from "@mui/icons-material/PinDrop";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import ForumIcon from "@mui/icons-material/Forum";
import askUsAQuestionIcon from "../assets/AskUsAQuestion.svg";
import TeamMemberCard from "../components/TeamMemberCard/TeamMemberCard";
import nomanImage from "../assets/noman.png";
import hamedImage from "../assets/hamed.png";
import mandyImage from "../assets/mandy.png";
import mattImage from "../assets/matt.png";
import marcusImage from "../assets/marcus.png";
import usaibImage from "../assets/usaib.png";
import SignUp from "../pages/SignUp";

const teamMembers = [
	{
		name: "Matthew Parker",
		profession: "CEO",
		description: (
			<>
				Exit, ZingFix, a home seller concierge and financier. 18 years real
				estate sales and development experience in Washington, California and
				Florida.
				<b style={{ color: "#003A7B" }}> Avid waterman.</b>
			</>
		),
		imageUrl: mattImage
	},
	{
		name: "Hamed Adibnatanzi",
		profession: "Head of Legal",
		description: (
			<>
				19 years and $1B + in residential and commercial real estate law across
				the U.S.A. Consummate family man,
				<b style={{ color: "#003A7B" }}> residential real estate investor.</b>
			</>
		),

		imageUrl: hamedImage
	},
	{
		name: "Noman Shaukat",
		profession: "CTO",
		description: (
			<>
				14 years Machine Learning, Backend Development Engineer Director.
				Skilled wood worker and craftsman,{" "}
				<b style={{ color: "#003A7B" }}>
					multi-state residential real estate investor.
				</b>
			</>
		),
		imageUrl: nomanImage
	},

	{
		name: "Mandy Kroetsch",
		profession: "CMO",
		description: (
			<>
				President in the Energy Industry. Certified Project Manager and Chemical
				Engineer.{" "}
				<b style={{ color: "#003A7B" }}>
					Avid ultramarathon runner and outdoor enthusiast.
				</b>
			</>
		),
		imageUrl: mandyImage
	},

	{
		name: "Marcus Rossi",
		profession: "COO",
		description: (
			<>
				23 years of leadership in U.S Military, Lieutenant Colonel, Marines. Experienced
				back country adventurer and father of twins.{" "}
				<b style={{ color: "#003A7B" }}>
					{" "}
					California investment real estate investor.
				</b>
			</>
		),
		imageUrl: marcusImage
	},
	{
		name: "Usaib Khan",
		profession: "Founding Engineer",
		description: (
			<>
				4 years full stack development experience. Student,{" "}
				<b style={{ color: "#003A7B" }}>Masters of Computer Engineering, </b>
				saving for first house.{" "}
			</>
		),
		imageUrl: usaibImage
	}
];

const aboutUsStyles = makeStyles((theme) => {
	return {
		aboutUsContainer: {
			padding: 0,
			margin: 0,
			marginTop: "75px",
			overflow: "hidden"
		},
		pilotSection: { backgroundColor: "#D9FAF5", padding: 40 },
		pilotDescription: {
			fontFamily: theme.typography.fontFamily,
			fontSize: "32px",
			fontWeight: theme.typography.fontWeightBold,
			color: theme.palette.primary.mainLight,
			[theme.breakpoints.down("sm")]: {
				fontSize: "24px"
			}
		},
		pilotMediumDescription: {
			fontSize: "22px",
			color: theme.palette.primary.mainLight,
			fontFamily: theme.typography.fontFamily,
			lineHeight: "136%",
			marginTop: "30px",
			[theme.breakpoints.down("sm")]: {
				fontSize: "18px"
			}
		},
		pilotSmallDescription: {
			marginTop: "40px",
			color: "#000",
			fontSize: "1.25rem"
		},
		pilotCenteredGrid: {
			display: "flex",
			justifyContent: "flex-end",
			[theme.breakpoints.down("md")]: {
				alignItems: "center",
				justifyContent: "center"
			}
		},
		pilotImageContainer: {
			position: "relative",
			display: "inline-block"
		},
		pilotImg: {
			width: "100%",
			height: "auto",
			maxWidth: "100%"
		},
		askQuestionButton: {
			cursor: "pointer",
			position: "absolute",
			bottom: "-40%",
			right: "-5%",
			[theme.breakpoints.down("md")]: {
				right: "-1%",
				bottom: "-1%"
			}
		},
		pilotSubheading: {
			fontSize: "2.125rem",
			fontFamily: theme.typography.fontFamily,
			color: theme.palette.primary.mainLight,
			fontWeight: theme.typography.fontWeightBold,
			[theme.breakpoints.down("sm")]: {
				fontSize: "1.625rem"
			}
		},
		pilotHeading: {
			fontSize: "2.125rem",
			fontFamily: theme.typography.fontFamily,
			color: "#000",
			fontWeight: theme.typography.fontWeightBold,
			[theme.breakpoints.down("sm")]: {
				fontSize: "1.625rem"
			}
		},
		processSection: {
			paddingTop: "100px",
			paddingBottom: "80px",
			[theme.breakpoints.down("xs")]: {
				paddingTop: "50px"
			}
		},
		processCenteredGridItem: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			[theme.breakpoints.up("lg")]: {
				alignItems: "flex-start",
				justifyContent: "flex-start"
			}
		},
		ellipseContainer: {
			width: 157,
			height: 157,
			borderRadius: "50%",
			backgroundColor: "#003A7B",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			[theme.breakpoints.down("md")]: {
				marginBottom: "35px"
			}
		},
		ellipseGridCont: {
			marginTop: "100px",
			[theme.breakpoints.down("sm")]: {
				marginTop: "80px"
			}
		},
		processSubHeading: {
			fontSize: "2.125rem",
			fontFamily: theme.typography.fontFamily,
			color: theme.palette.primary.mainLight,
			[theme.breakpoints.down("md")]: {
				textAlign: "center"
			},
			[theme.breakpoints.down("sm")]: {
				fontSize: "1.375rem"
			}
		},
		processSmallDescription: {
			marginTop: "15px",
			color: "#000",
			fontSize: "1.25rem",
			[theme.breakpoints.down("md")]: {
				textAlign: "center"
			},
			[theme.breakpoints.down("sm")]: {
				fontSize: "1.125rem"
			}
		},
		largeTitle: {
			color: theme.palette.primary.mainLight,
			fontSize: "2.5rem",
			fontWeight: theme.typography.fontWeightBold,
			fontFamily: theme.typography.fontFamily,
			[theme.breakpoints.down("sm")]: {
				fontSize: "2rem"
			}
		},
		whatAboutAgentsSection: {
			paddingBottom: "80px",
			[theme.breakpoints.down("sm")]: {
				paddingBottom: "50px"
			}
		},
		button: {
			padding: "10px 18px",
			background: "#003A7B",
			fontFamily: theme.typography.fontFamily,
			fontWeight: theme.typography.fontWeightSemiBold,
			fontSize: "1rem",
			lineHeight: "normal",
			color: "#FFFFFF",
			textTransform: "none",
			marginTop: "30px",
			"&:hover": {
				color: "#000",
				backgroundColor: theme.palette.primary.white
			}
		},
		signup: {
			paddingBottom: "120px",
			maxWidth: "1536px",
			[theme.breakpoints.down("sm")]: {
				paddingTop: "70px"
			}
		}
	};
});

const stepperData = [
	{
		label: "We leverage online platforms to search for properties",
		Icon: CodeIcon
	},
	{
		label: "We meticulously research the properties that catch our attention",
		Icon: ZoomInIcon
	},
	{ label: "We visit the properties in person", Icon: PinDropIcon },
	{
		label: "Our real estate attorney prepares the offer.",
		Icon: LocationCityIcon
	},
	{
		label: (
			<>
				<span
					style={{ color: "#003A7B", fontWeight: "bold", fontSize: "1.5rem" }}
				>
					And the negotiation?
				</span>
				<br />
				That’s our expertise, we handle it personally.
			</>
		),
		Icon: ForumIcon
	}
];

const AboutUs = () => {
	const classes = aboutUsStyles();
	const [askQuestion, setAskQuestion] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div style={{ width: "100%" }}>
			<Helmet>
				<title>Alokee | About Us</title>
			</Helmet>
			<Container maxWidth={"xl"} className={classes.aboutUsContainer}>
				<AppBar whiteBackground={true} tealBackground={true} />
				<Container maxWidth={"xl"} className={classes.pilotSection}>
					<Grid container justifyContent="space-between" alignItems="center">
						<Grid item md={12} lg={6}>
							<Typography className={classes.pilotDescription}>
								Buying a home should be easy, inexpensive, and painless.
							</Typography>
							<Typography
								className={classes.pilotMediumDescription}
								style={{ marginBottom: "30px" }}
							>
								Alokee simplifies and reduces the cost of purchasing a home so
								you can just focus on buying your dream home.
							</Typography>
							<Typography
								style={{ marginTop: "40px", color: "#000" }}
							></Typography>
						</Grid>
						<Grid item md={12} lg={6} className={classes.pilotCenteredGrid}>
							<div className={classes.pilotImageContainer}>
								<img
									src={pilotImage}
									className={classes.pilotImg}
									alt="Pilot image"
								/>
								<img
									src={askUsAQuestionIcon}
									className={classes.askQuestionButton}
									alt="ask us a question icon"
									role="button"
									aria-haspopup="dialog"
									tabIndex="0"
									aria-label="Open Chat Dialog"
									onClick={() => {
										setAskQuestion(true);
									}}
								></img>
							</div>
						</Grid>
					</Grid>
				</Container>
				<Container maxWidth="md" className={classes.processSection}>
					<Grid
						container
						direction={"column"}
						justifyContent={"center"}
						alignItems={"center"}
					>
						<Grid item xs={12} style={{ textAlign: "center" }}>
							<Typography className={classes.pilotHeading}>
								EXPERTS YOU CAN TRUST
							</Typography>
							<Typography className={classes.pilotSubheading}>
								Who we are
							</Typography>
						</Grid>
					</Grid>
				</Container>
				<Container maxWidth="md" className={classes.whatAboutAgentsSection}>
					<Grid
						container
						justifyContent={"center"}
						alignItems={"baseline"}
						direction={"row"}
						spacing={2}
					>
						{teamMembers.map((data, key) => {
							return (
								<Grid item xs={12} sm={12} md={4} lg={4} key={key}>
									<TeamMemberCard
										profession={data.profession}
										name={data.name}
										description={data.description}
										imageUrl={data.imageUrl}
									/>
								</Grid>
							);
						})}
					</Grid>
				</Container>
			</Container>
			<Container className={classes.signup}>
				<SignUp />
			</Container>
			<Footer />
		</div>
	);
};

export default AboutUs;
