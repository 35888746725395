import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { AppBar } from "../components/AppBar";
import { Container, makeStyles, Typography } from "@material-ui/core";
import FAQAccordion from "../components/FAQS/FAQAccordion";
import Footer from "../components/footer/footer";
import { Link } from "react-router-dom";

const faqsStyles = makeStyles((theme) => {
	return {
		faqsContainer: {
			marginTop: "150px",
			paddingBottom: "400px",
			[theme.breakpoints.down("sm")]: {
				paddingBottom: "120px"
			}
		},
		smallTitle: {
			color: "#000",
			fontFamily: theme.typography.fontFamily,
			fontSize: "2rem",
			lineHeight: "138%",
			[theme.breakpoints.down("sm")]: {
				fontSize: "1.375rem"
			}
		},
		largeTitle: {
			color: theme.palette.primary.mainLight,
			fontFamily: theme.typography.fontFamily,
			fontSize: "2.5rem",
			fontWeight: theme.typography.fontWeightBold,
			marginBottom: "125px",
			[theme.breakpoints.down("sm")]: {
				fontSize: "1.75rem",
				marginBottom: "70px"
			}
		},
		moreTopMargin: {
			marginTop: "125px",
			[theme.breakpoints.down("sm")]: {
				marginTop: "90px"
			}
		}
	};
});

const homeBuyingProcessFAQs = [
	{
		q: "How this works?",
		a: (
			<ul>
				<Typography component="h1" style={{ color: "#003A7B" }}>
					1. Search for homes using your favorite real estate sites, or directly
					on Alokee. Tour homes via open houses, or use the Alokee platform to
					request a tour.
				</Typography>
				<Typography component="h1" style={{ color: "#003A7B" }}>
					2. Use Alokee’s “ask us” tool (or use AI tools like ChatGPT) to learn
					about neighborhoods, schools, etc.
				</Typography>
				<Typography component="h1" style={{ color: "#003A7B" }}>
					3. Decide how you will finance your purchase. If you plan to use cash,
					you will need a “proof of funds.” If you plan to use a loan, you will
					need a “preapproval.”
				</Typography>
				<Typography component="h1" style={{ color: "#003A7B" }}>
					4. Once you find the home you want, use the Alokee platform to ask for
					additional property information if needed. A licensed real estate
					attorney and broker will support you.
				</Typography>
				<Typography component="h1" style={{ color: "#003A7B" }}>
					5. When you are ready, make an offer directly on Alokee. Then
					negotiate and close on the home, all through the Alokee platform. You
					control the process. When the deal is closed, collect your Alokee Cash
					Advantage ($30,000 on average in the San Francisco Bay Area).
				</Typography>
			</ul>
		)
	},
	{
		q: "What is the Alokee Cash Advantage?",
		a: (
			<ul>
				<Typography component="h1" style={{ color: "#003A7B" }}>
					Our service fee is $9,999 and you keep the balance of any seller
					concession as the Alokee Cash Advantage.
				</Typography>
				<Typography component="h1" style={{ color: "#003A7B" }}>
					For most homes, the home seller will offer you a predetermined cash
					concession, generally 2.5-3% of the purchase price. Using Alokee, you
					will keep the entire concession less our $9,999 fee.
				</Typography>
				<Typography component="h1" style={{ color: "#003A7B" }}>
					For example, if you use Alokee to buy a $1,300,000 home, a seller may
					offer a concession of $39,000 at closing.* Traditional real estate
					agents will ask for a commission (usually 2.5-3% of sales price) to
					represent you in a transaction - which requires you to pay the full
					concession to your agent. With Alokee, you simply pay a flat
					transaction fee, and you can pocket the concession. You are thus
					saving $29,000 ($39,000-$9,999) vs traditional buyers agents. You can
					use this savings for anything you want - home retrofits, buying down
					the loan, or paying off your house faster.
				</Typography>
			</ul>
		)
	},
	{
		q: "How do I see homes?",
		a: "You can use open houses or request showings from Alokee. Showings are $199 each deducted from your Alokee Cash Bonus at closing."
	},
	{
		q: "How do I decide how much I should offer for a Home?",
		a: "In the past, real estate agents had information about homes and neighborhoods. Today, artificial intelligence can provide very accurate information to give you the edge when competing to buy a home. You can use Alokee’s Price Predictor to estimate what offer you should submit to have a strong chance of getting your dream home."
	},
	{
		q: "Can Alokee help me get a home loan?",
		a: "Yes. We can introduce you to preferred lenders who may give you further cash bonuses or cash credits.",
		a: (
			<div>
				Yes. We can introduce you to{" "}
				<Link
					to={
						window.location.pathname.startsWith("/app")
							? "/app/financing"
							: "/financing"
					}
					style={{
						textDecoration: "underline"
					}}
					target="_blank"
				>
					{"preferred lenders"}
				</Link>{" "}
				who may give you further cash bonuses or cash credits.
			</div>
		)
	},
	{
		q: "Can I make an offer on an unlisted home?",
		a: "Yes."
	},
	{
		q: "How long does it take to make an offer?",
		a: "Two-ten minutes for most people."
	},
	{
		q: "Can I Arrange a Home Inspection Through Alokee?",
		a: "Yes, we help you complete an inspection with a licensed professional of your choice."
	},
	{
		q: "Does My Transaction Have Title Insurance and Professional Escrow Facilitation?",
		a: "Yes."
	},
	{
		q: "What if I am unhappy with Alokee?",
		a: "Cancel anytime before being in contract to buy a home. Once you are in contract, under most circumstances we will remain your real estate service provider until closing."
	}
];
const alokeeFAQs = [
	{
		q: "How do I contact Alokee for support?",
		a: "Once you have registered, every customer has full technical support via messaging. Alokee Plus subscribers receive full real estate broker and real estate attorney support via messaging, email and video calls."
	},
	{
		q: "How Does Alokee Keep People from Making “Scam” Offers?",
		a: "Alokee uses security software and live humans to vet each offer. We take this very seriously. All of our offers, when presented, will be of the highest quality."
	},
	{
		q: "Is Alokee a Licensed Real Estate Broker?",
		a: "Yes, Alokee carries license Alokee Inc. CA DRE 02201041."
	},
	{
		q: "How Much Experience Do You Have in Real Estate?",
		a: "Alokee founders bring over fifteen years of brokerage, law and technologic experience and $1 billion in real estate experience from across the U.S.A. We've got you covered."
	},
	{
		q: "How Does Alokee Keep My Data Safe?",
		a: "We limit stored data to an absolute minimum. Only the agent and assistants working on your case will have access to your data."
	}
];

const FAQs = () => {
	const classes = faqsStyles();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div style={{ width: "100%" }}>
			<Helmet>
				<title>Alokee | FAQs</title>
			</Helmet>
			<Container maxWidth="lg" className={classes.faqsContainer}>
				<AppBar whiteBackground={true} />
				<Typography className={classes.smallTitle}>
					Got questions related to home buying?
				</Typography>
				<Typography className={classes.largeTitle}>We have answers!</Typography>
				<Typography
					className={classes.smallTitle}
					style={{ color: "#003A7B", marginBottom: "40px" }}
				>
					Home buying process FAQ’s
				</Typography>
				<FAQAccordion faqs={homeBuyingProcessFAQs} />
				<Typography
					className={`${classes.smallTitle} ${classes.moreTopMargin}`}
					style={{ color: "#003A7B", marginBottom: "40px" }}
				>
					About Alokee FAQ’s
				</Typography>
				<FAQAccordion faqs={alokeeFAQs} />
			</Container>
			<Footer />
		</div>
	);
};

export default FAQs;
