import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Typography, Box, useMediaQuery, Button } from "@material-ui/core";
import { Container, Grid, Card, CardContent, CardActions } from "@mui/material";
import { makeStyles, useTheme } from "@material-ui/styles";
import heroHome from "../assets/newHeroImage.png";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import HomeIcon from "@mui/icons-material/Home";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Footer from "../components/footer/footer";
import WhyAlokee from "../components/WhyAlokee/WhyAlokee";
import HorizontalStepper from "../components/Steppers/HorizontalStepper";
import VerticalStepper from "../components/Steppers/VerticalStepper";
import { AppBar } from "../components/AppBar";
import { HeroSection } from "../components/HeroSection/HeroSection";
import { Toast } from "../components/core";
import SignUp from "./SignUp";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import noExclContracts from "../assets/noExclContracts.svg";
import doItYourself from "../assets/doItYourself.svg";
import saveTime from "../assets/saveTime.svg";
import saveMoney from "../assets/saveMoney.svg";
import { SendPageEvent } from "../configs/googleAnalytics";

const homeStyles = makeStyles((theme) => {
	return {
		faqSubtitle: {
			color: "#003A7B",
			fontFamily: theme.typography.fontFamily,
			fontSize: "1.125rem",
			fontWeight: "600",
			lineHeight: "130.5%" /* 23.49px */,
			textAlign: "center"
		},
		faqParagraph: {
			color: "#000",
			textAlign: "center",
			width: "75%",
			fontFamily: theme.typography.fontFamily,
			fontSize: "1rem",
			fontWeight: theme.typography.fontWeightRegular,
			marginTop: "8px",
			lineHeight: "132%" /* 21.12px */
		},
		faqSection: {
			paddingTop: "80px",
			backgroundColor: "#F8F9FA",
			paddingBottom: "100px",
			[theme.breakpoints.down("sm")]: {
				paddingBottom: "70px"
			}
		},
		SmallTitle: {
			color: "#000",
			textAlign: "center",
			fontFamily: theme.typography.fontFamily,
			fontSize: "2rem",
			fontWeight: theme.typography.fontWeightRegular,
			lineHeight: "138%",
			[theme.breakpoints.down("sm")]: {
				fontSize: "1.5rem"
			}
		},
		LargeTitle: {
			color: theme.palette.primary.mainLight,
			fontFamily: theme.typography.fontFamily,
			fontSize: "2.5rem",
			fontWeight: theme.typography.fontWeightSemiBold,
			textAlign: "center",
			marginBottom: "40px",
			[theme.breakpoints.down("sm")]: {
				fontSize: "1.75rem"
			}
		},
		smallerTitle: {
			color: "#FFF",
			fontFamily: theme.typography.fontFamily,
			fontSize: "1.625rem",
			fontWeight: theme.typography.fontWeightSemiBold,
			[theme.breakpoints.down("sm")]: {
				fontSize: "1.375rem"
			}
		},
		stepsSection: {
			paddingTop: "100px",
			paddingBottom: "180px",
			[theme.breakpoints.down("sm")]: {
				paddingTop: "78px",
				paddingBottom: "30px"
			}
		},
		journeySection: {
			paddingLeft: "0 !important",
			paddingRight: "0 !important",
			position: "relative",
			paddingBottom: "25rem"
		},
		journeyTitleContainer: {
			background: "#003A7B",
			paddingTop: "50px",
			paddingLeft: 0,
			paddingRight: 0,
			paddingBottom: "200px"
		},
		journeyCommentText: {
			fontSize: "1rem"
		},
		authorFrom: {
			marginLeft: "10px",
			color: "#FFF",
			fontSize: "1.25rem",
			[theme.breakpoints.down("sm")]: {
				fontSize: "1.125rem"
			}
		},
		signup: {
			paddingBottom: "120px"
		},
		faqHover: {
			borderRadius: 8,
			height: 227
		}
	};
});

const comments = [
	{
		author: "Katherine",
		profession: "Professor",
		from: "Oakland",
		comment:
			"I was skeptical. Buying a home is scary and I needed to buy a home in a state - California - I had never bought in before. I also knew buying is hard. How could Alokee deliver? I think having an experienced attorney on call when I needed it was huge, that and the fact I could get any information I needed without asking a real estate agent. I highly recommend Alokee."
	},
	{
		author: "Ryo",
		profession: "Product Manager",
		from: "San Diego",
		comment:
			"I've bought two houses before. In both cases, I found the houses, researched the neighborhood and even toured them without an agent at open houses. All I needed was the ability to gather the disclosure documents, make an offer and close. Alokee exceeded my expectations because I did all these things AND had personal support from their experienced real estate agent (who was also a real estate lawyer)."
	},
	{
		author: "Matthew",
		profession: "Startup Founder",
		from: "San Francisco",
		comment:
			"Does anyone really think real estate agents have their best interests in mind? I certainly don't. I didn't feel like the Alokee expert that helped me was trying to push me or influence me... at all. And, when I wanted to gather information or make an offer, I simply did. The old real estate model should be afraid of the simplicity and transparency Alokee offers."
	},
	{
		author: "Li",
		profession: "Director of Sustainability",
		from: "San Francisco",
		comment:
			"I had no idea you don't need a real estate agent to buy a house! I also had no idea so many of my friends where buying a house without an agent! All you need is someone familiar with the purchase forms to help you fill them out. Well, Alokee gives you a real estate lawyer for that! And, there didn't seem to be any sales tactics."
	},
	{
		author: "Marcus",
		profession: "Product Operations Lead",
		from: "San Jose",
		comment:
			"The old way (of buying a home) and paying for it - a flat 3% fee - never made any sense to me. With Alokee, I paid for what I used. The value seemed really high to me. I think the fact that they have automated the offer made the process much easier. Next time I buy, I will use them again. If you are buying and you like getting involved in your business deals, there's no better way to buy a home."
	},
	{
		author: "Melissa",
		profession: "Program Manager",
		from: "Los Angeles",
		comment:
			'What impresses me is that their process feels more personal and trustworthy than the usual way. Within minutes of signing up, multiple members of their team reached out to make sure I felt like their process was serving me correctly. This is the way real estate should be; simpler, more straightforward and less "pushy."'
	}
];

const stepperData = [
	{ stepNumber: "1", label: "Discover Your Dream Home", Icon: HomeIcon },
	{ stepNumber: "2", label: "Make an Offer", Icon: AttachMoneyIcon },
	{
		stepNumber: "3",
		label: "Close Seamlessly",
		Icon: SentimentSatisfiedAltIcon
	}
];

const faqs = [
	{
		title: "No Exclusive Contracts",
		description:
			"Enjoy freedom and transparency without any binding agreements",
		icon: noExclContracts
	},
	{
		title: "Do It Yourself",
		description:
			"Make offers and negotiate directly with sellers or seller agents, just like buying stocks or airline tickets",
		icon: doItYourself
	},
	{
		title: "Save Time",
		description:
			"Say goodbye to waiting for real estate agents, get immediate responses",
		icon: saveTime
	},
	{
		title: "Save Money",
		description:
			"Save $29,901 compared to using a traditional agent who would keep that amount as commission.",
		icon: saveMoney
	}
];

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const HomeA = () => {
	const classes = homeStyles();
	const theme = useTheme();
	const [toast, setToast] = useState({
		state: "close",
		message: "",
		type: ""
	});
	const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
	const mdUp = useMediaQuery(theme.breakpoints.up("md"));
	const mdDown = useMediaQuery(theme.breakpoints.down("md"));
	const smDown = useMediaQuery(theme.breakpoints.down("sm"));
	const [activeStep, setActiveStep] = useState(0);
	const [groupedComments, setGroupedComments] = useState([]);
	const [faqActiveStep, setFaqActiveStep] = useState(0);
	const faqMaxSteps = faqs.length;
	SendPageEvent({
		hitType: "pageview",
		page: window.location.pathname,
		title: "Home Version A"
	});

	useEffect(() => {
		if (lgUp) {
			// Group comments into subarrays of 3 for lg and larger screens
			const grouped = [];
			for (let i = 0; i < comments.length; i += 3) {
				grouped.push(comments.slice(i, i + 3));
			}
			setGroupedComments(grouped);
		} else {
			// For smaller screens, use individual comments
			setGroupedComments(comments.map((comment) => [comment]));
		}
	}, [comments, lgUp]);

	// Update maxSteps based on groupedComments
	const maxSteps = groupedComments.length;

	const handleStepChange = (step) => {
		setActiveStep(step);
	};

	const handleDotClick = (step) => {
		setActiveStep(step);
	};

	const handleFaqStepChange = (step) => {
		const newStep = step >= faqs.length ? 0 : step;
		setFaqActiveStep(newStep);
	};

	const handleFaqDotClick = (step) => {
		setFaqActiveStep(step);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div>
			<Helmet>
				<title>Alokee | Home</title>
			</Helmet>
			<Toast {...toast} setState={setToast} />
			<div>
				<AppBar background={false} />
				<HeroSection
					heroImage={heroHome}
					heroTextOne={"The Revolutionary "}
					heroTextTwo={"Digital Real Estate Experience"}
					heroDescriptionTextOne={"Looking for your dream home?"}
					heroDescriptionTextTwo={"Use Alokee to make an offer and close."}
					heroDescriptionTextThree={"Unlock savings of nearly $30,000."}
					isDashboard={false}
				/>
			</div>
			<div style={{ width: "100%" }}>
				<Container maxWidth="false" className={classes.faqSection}>
					<Typography className={classes.SmallTitle}>
						A self guided experience.
					</Typography>
					<Typography className={classes.LargeTitle}>
						Full expert support when you need it!
					</Typography>
					{mdUp ? (
						<Grid
							container
							spacing={5}
							flexDirection="row"
							alignItems="center"
							justifyContent="center"
							style={{
								marginTop: 10
							}}
						>
							{faqs.map((faq) => (
								<Grid item lg={3} md={4} sm={4} xs={12} key={faq.title}>
									<Box
										display="flex"
										flexDirection="column"
										alignItems="center"
										className={classes.faqHover}
									>
										<img src={faq.icon} alt={faq.title} />
										<Typography className={classes.faqSubtitle}>
											{faq.title}
										</Typography>
										<Typography className={classes.faqParagraph}>
											{faq.description}
										</Typography>
									</Box>
								</Grid>
							))}
						</Grid>
					) : (
						<div>
							<AutoPlaySwipeableViews
								axis={theme.direction === "rtl" ? "x-reverse" : "x"}
								index={faqActiveStep}
								onChangeIndex={handleFaqStepChange}
								enableMouseEvents
							>
								{faqs.map((faq, index) => (
									<div key={faq.title}>
										{Math.abs(faqActiveStep - index) <= 2 ? (
											<Box
												display="flex"
												flexDirection="column"
												alignItems="center"
											>
												<img src={faq.icon} alt={faq.title} />
												<Typography className={classes.faqSubtitle}>
													{faq.title}
												</Typography>
												<Typography className={classes.faqParagraph}>
													{faq.description}
												</Typography>
											</Box>
										) : null}
									</div>
								))}
							</AutoPlaySwipeableViews>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									marginTop: "20px"
								}}
							>
								{Array.from({ length: faqMaxSteps }, (_, index) => (
									<Button
										aria-label={`Go to Step ${index + 1} of Home FAQs`}
										key={index}
										style={{
											margin: "0 4px",
											padding: 0,
											minWidth: "20px",
											height: "20px",
											borderRadius: "20px",
											backgroundColor:
												faqActiveStep === index ? "#003A7B" : "lightgrey"
										}}
										onClick={() => handleFaqDotClick(index)}
									/>
								))}
							</div>
						</div>
					)}
				</Container>
				<Container className={classes.stepsSection} maxWidth="xl">
					<Typography className={classes.SmallTitle}>
						It’s your home, you guide the experience.
					</Typography>
					<Typography className={classes.LargeTitle}>
						We’ve got your back the whole way.
					</Typography>
					<Box display="flex" alignItems="center" justifyContent="center">
						{mdUp ? (
							<HorizontalStepper stepperData={stepperData} />
						) : (
							<VerticalStepper
								stepperData={stepperData}
								lineStyle="dashed"
								lineColor="#000"
								iconColor="#000"
								lineLength="150px"
							/>
						)}
					</Box>
				</Container>
				<Container maxWidth="false" className={classes.journeySection}>
					<Container maxWidth="false" className={classes.journeyTitleContainer}>
						<Typography
							className={classes.LargeTitle}
							style={{ color: "#FFF" }}
						>
							Customer Success Stories
						</Typography>
					</Container>
					<div
						style={{
							position: "absolute",
							bottom: "6rem",
							left: 0,
							right: 0
						}}
					>
						<AutoPlaySwipeableViews
							index={activeStep}
							onChangeIndex={handleStepChange}
							enableMouseEvents
							slideStyle={{ overflow: "hidden" }}
							interval={lgUp ? 20000 : 10000}
						>
							{groupedComments.map((group, index) => (
								<Grid
									container
									key={index}
									justifyContent={"center"}
									alignItems={"center"}
									spacing={mdDown ? 0 : 5}
								>
									{group.map((comment, cIndex) => (
										<Grid item key={cIndex}>
											<Card
												variant="outlined"
												elevation={0}
												style={{
													display: "flex",
													flexDirection: "column",
													justifyContent: "space-between",
													height: "28rem",
													borderRadius: "8px",
													width: 360
												}}
											>
												<CardContent style={{ flex: 1, padding: "32px 22px" }}>
													<Typography className={classes.journeyCommentText}>
														{comment.comment}
													</Typography>
												</CardContent>
												<CardActions
													style={{
														flexDirection: "column",
														alignItems: "flex-start",
														background: "#02A689",
														padding: "10px"
													}}
												>
													<Typography
														variant="subtitle1"
														className={classes.smallerTitle}
														style={{ marginLeft: "10px" }}
													>
														{comment.author}
													</Typography>
													<Typography className={classes.authorFrom}>
														{comment.profession} from {comment.from}
													</Typography>
												</CardActions>
											</Card>
										</Grid>
									))}
								</Grid>
							))}
						</AutoPlaySwipeableViews>
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								marginTop: "20px"
							}}
						>
							{Array.from({ length: maxSteps }, (_, index) => (
								<Button
									key={index}
									aria-label={`Go to Step ${
										index + 1
									} of Customer Success Stories`}
									style={{
										margin: "0 4px",
										padding: 0,
										minWidth: "20px",
										height: "20px",
										borderRadius: "20px",
										backgroundColor:
											activeStep === index ? "#003A7B" : "lightgrey"
									}}
									onClick={() => handleDotClick(index)}
								/>
							))}
						</div>
					</div>
				</Container>
				<Container maxWidth="xl">
					<WhyAlokee
						whyAlokeeHeading={"Why is using Alokee different?"}
						alokeeProDescriptionText={
							"Showings $199 collected at closing from your cash bonus"
						}
						bonusPercentage={0.025}
						commission={9999}
						costOfServiceText={"Cost of service"}
						costOfService={"$9,999"}
						alokeeGivesYouText={"Pocket the Concession"}
						alokeeGivesYou={"$29,901"}
						homeTwo={false}
					/>
				</Container>
				<Container
					maxWidth="xl"
					sx={{
						paddingTop: 5
					}}
					className={classes.signup}
				>
					<SignUp />
				</Container>
				<Footer />
			</div>
		</div>
	);
};

export default HomeA;
