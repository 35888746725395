import React from "react";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	Button,
	Slide,
	makeStyles
} from "@material-ui/core";
import { Typography, Grid } from "@mui/material";
const useStyles = makeStyles((theme) => ({
	// These fixed widths prevent responsiveness

	// dialogMinWidth: {
	// 	minWidth: "800px !important"
	// },
	innerContainer: {
		// width: 440,
		paddingBottom: 30
		// alignSelf: "center"
	},
	contentHeading: {
		fontSize: "2.5rem",
		fontFamily: theme.typography.fontFamily,
		color: theme.palette.primary.main,
		fontWeight: theme.typography.fontWeightBold,
		[theme.breakpoints.down('sm')]: {
			fontSize: "1.75rem"
		},
		paddingTop: 20
	},
	contentText: {
		color: "#000",
		fontFamily: theme.typography.fontFamily,
		fontSize: "1rem",
		textAlign: "center",
	},
	actions: {
		paddingLeft: 22,
		paddingRight: 22,
		paddingBottom: 30
	},
	contact: {
		color: "#000",
		fontFamily: "Rubik !important",
		fontSize: "1rem"
	}
}));

const Transition = React.forwardRef((props, ref) => (
	<Slide direction="up" ref={ref} {...props} />
));
const TextDialog = ({
	message,
	state,
	stateHandler,
	onClose,
	submitHandler,
	children,
	showAvatar,
	Avatar,
	showActions,
	hideCancel,
	dialogDescription,
	agreementButtonEnabled,
	buttonTitle,
	textDialog,
	showContact,
	setChatForceOpen,
	agreementDialog
}) => {
	const classes = useStyles();
	const handleClose = () => {
		stateHandler((prevState) => {
			return { ...prevState, state: false };
		});
		if (onClose) {
			onClose();
		}
	};
	const handleSubmit = () => {
		
		if (submitHandler) {
			submitHandler();
		}
        stateHandler((prevState) => {
					return { ...prevState, state: false };
				});
	};

	return (
		<Dialog
			open={state}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose}
			maxWidth={agreementDialog ? "md" : "sm"}
		// classes={{ paper: textDialog ? classes.dialogMinWidth : "" }}
		>
			{showAvatar && Avatar}
			<div className={agreementDialog ? "" : classes.innerContainer}>
				<DialogContent>
					<DialogContentText className={classes.contentHeading}>
						{message}
					</DialogContentText>
					<div id="dialog-description">
						<Typography style={{ color: "#000", paddingTop: "20px", paddingBottom: "40px" }}>
							{dialogDescription}
						</Typography>
					</div>
					{children}
				</DialogContent>
				{showActions && (
					<DialogActions className={classes.actions}>
						<Grid container flexDirection="column" rowSpacing={2}>
							{!hideCancel && (
								<Grid item xs={12} sm={8} md={6}>
									<Button
										onClick={handleClose}
										style={{
											border: "1px solid",
											textTransform: "none",
											fontWeight: "700"
										}}
										color="primary"
										variant="outlined"
										fullWidth={true}
									>
										Cancel
									</Button>
								</Grid>
							)}
							{
								<Grid item xs={12} sm={8} md={6}>
									<Button
										disabled={
											agreementButtonEnabled ? agreementButtonEnabled : false
										}
										onClick={handleSubmit}
										color="primary"
										variant="contained"
										style={{
											textTransform: "none",
											fontWeight: "700"
										}}
										fullWidth={true}
									>
										{buttonTitle || "Ok"}
									</Button>
								</Grid>
							}
						</Grid>
					</DialogActions>
				)}
				{showContact && (
					<div id="dialog-description">
						<Typography className={classes.contact} style={{ marginTop: 30, marginLeft: 22 }}>
							Or{" "}
							<span
								onClick={() => {
									setChatForceOpen(true);
								}}
								style={{ color: "#003a7b", cursor: "pointer" }}
							>
								Contact Alokee{" "}
							</span>
							for any help or questions you may have.
						</Typography>
					</div>
				)}
			</div>
		</Dialog>
	);
};

export default TextDialog;
