import {
	IconButton,
	Grid,
	Avatar,
	Typography,
	InputBase,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions
} from "@mui/material";
import SendIcon from "@material-ui/icons/Send";
import React, { useEffect, useRef, useState } from "react";
import useStyles from "./style";
import { Close } from "@mui/icons-material";
import Logo from "../../assets/Logo.png";
import Message from "./Message";
import { useUserState } from "../../context/UserContext";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router";
import notification from "../../assets/notification.mp3";
import { sendEmailToAlokee } from "../../services/propertyForm";
function ChatBox({
	open,
	handleClose,
	socket,
	uniqueKey,
	oldChat,
	createChat,
	setBadge
}) {
	const classes = useStyles();
	const [messages, setMessages] = useState([]);
	const [chatMessage, setChatMessage] = useState("");
	const userDetails = useUserState();
	const { user } = useAuth0();
	const { pathname } = useLocation();
	const myRef = useRef();
	const notificationSound = new Audio(notification);

	useEffect(() => {
		setMessages(oldChat);
	}, [oldChat]);
	useEffect(() => {
		if (userDetails?.userProfile?.auth0Id) {
			socket.emit("setUserId", userDetails?.userProfile?.auth0Id);
		}
	}, []);
	useEffect(() => {
		setTimeout(() => {
			myRef.current?.scroll({
				top: myRef.current?.scrollHeight,
				behavior: "smooth"
			});
		}, 500);
	}, [open]);
	useEffect(() => {
		socket.on(
			"adminReply",
			(message) => {
				if (!open) {
					setBadge(1);
				}
				if (message.from != userDetails?.userProfile?.firstName) {
					notificationSound.play();
				}
				setMessages((prevState) => [...prevState, message]);
				myRef.current?.scroll({
					top: myRef.current?.scrollHeight,
					behavior: "smooth"
				});
			},
			() => {}
		);
	}, [socket]);
	const sendMessage = async () => {
		if (chatMessage.length > 0) {
			socket.emit(
				"userMessage",
				{
					room: userDetails?.userProfile?.auth0Id,
					from: userDetails?.userProfile?.firstName,
					text: chatMessage,
					url: pathname,
					createdAt: new Date()
				},
				() => {}
			);
			setMessages((prevState) => [
				...prevState,
				{
					room: userDetails?.userProfile?.auth0Id,
					from: userDetails?.userProfile?.firstName,
					text: chatMessage,
					url: pathname,
					createdAt: new Date()
				}
			]);

			try {
				await createChat({
					uniqueKey: userDetails?.userProfile?.auth0Id,
					from: userDetails?.userProfile?.firstName,
					text: chatMessage,
					url: pathname,
					createdAt: new Date()
				});

				if (
					!(
						new Date(
							messages[messages.length - 1].createdAt
						).toLocaleDateString() == new Date().toLocaleDateString()
					)
				) {
					await sendEmailToAlokee({
						details: {
							from: {
								email: "offer@alokee.com",
								name: "Alokee"
							},
							subject: "New Conversation Started by the User."
						},
						dynamicTemplateData: {
							name: userDetails?.userProfile?.firstName,
							email: userDetails?.userProfile?.email,
							message: chatMessage
						}
					});
				}
			} catch (e) {}
			setChatMessage("");
			myRef.current?.scroll({
				top: myRef.current?.scrollHeight + 100,
				behavior: "smooth"
			});
		}
	};
	const keyPress = (e) => {
		if (e.shiftKey && e.keyCode == 13) {
			return;
		}
		if (e.keyCode == 13) {
			sendMessage();
			return;
		}
	};
	return (
		<Dialog
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			open={open}
			onClose={() => {
				handleClose();
				setBadge(0);
			}}
			fullWidth="xs"
			maxWidth="xs"
			PaperProps={{
				style: {
					position: "fixed",
					bottom: 0,
					right: 0,
					margin: "16px"
				}
			}}
			disableScrollLock
		>
			<DialogTitle sx={{ backgroundColor: "#003A7B", position: "relative" }}>
				<Grid container justifyContent="space-between">
					<Grid item></Grid>
					<Grid item>
						<IconButton
							aria-haspopup="true"
							color="inherit"
							onClick={() => {
								handleClose();
								setBadge(0);
							}}
						>
							<Close className={classes.closeIcon} />
						</IconButton>
					</Grid>
				</Grid>
				<Grid container alignItems="center" columnSpacing={1}>
					<Grid item xs={2}>
						<Avatar src={Logo} />
					</Grid>
					<Grid item xs={10}>
						<Typography variant={"body1"} className={classes.heading}>
							Alokee
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant={"caption"} className={classes.subHeading}>
							Call 888.666.1360. or, ask a question here. Please include your
							email address or phone number and we will respond with the method
							given. Thanks!
						</Typography>
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent
				id="message-area"
				ref={myRef}
				className={classes.messagesArea}
			>
				{messages &&
					messages?.length > 0 &&
					messages.map((data) => {
						return (
							<Message
								userDetails={userDetails}
								user={user}
								text={data.text}
								data={data}
							/>
						);
					})}
			</DialogContent>
			<DialogActions sx={{ borderTop: "solid 1px #686868" }}>
				<Grid
					container
					direction="row"
					alignItems="center"
					className={classes.input}
					justifyContent="space-around"
				>
					<Grid item xs={10} sm={10} lg={10} xl={10} md={10}>
						<InputBase
							autoFocus
							onKeyDown={keyPress}
							placeholder="Write a message"
							fullWidth={true}
							value={chatMessage}
							onChange={(e) => {
								setChatMessage(e.target.value);
							}}
							multiline
							maxRows={3}
						/>
					</Grid>
					<Grid item xs={2} sm={2} lg={2} xl={2} md={2}>
						<IconButton
							onClick={() => {
								sendMessage();
							}}
							disabled={chatMessage.length ? false : true}
							type="submit"
							autoFocus={true}
						>
							<SendIcon color={chatMessage.length ? "primary" : ""}></SendIcon>
						</IconButton>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	);
}

export default ChatBox;
