import Login from "./pages/Login";
import DashboardLayout from "./components/layout/DashboardLayout";
import MainLayout from "./components/layout/MainLayout";
import CompleteProfile from "./components/Profile/CompleteProfile";
import SignUp from "./pages/SignUp";
import { ProtectedRoute, PublicRoute } from "../src/components/routes";
import Dashboard from "./pages/Dashboard";
import { Navigate } from "react-router-dom";
import PropertyForm from "./components/Properties/PropertyForm";
import PropertyDetails from "./components/Properties/PropertyDetails";
import ViewLayout from "./components/layout/ViewLayout";
import SigningPage from "./pages/SigningPage";
import Form from "./components/OfferFrom/Form";
import Redirect from "./pages/Redirect";
import Properties from "./components/Admin/PropertyTable";
import SigningPageOtherRecipient from "./pages/SigningPageOtherRecipient";
import RedirectRecipient from "./pages/RedirectRecipient";
import RecipientFrom from "./components/Recipients/RecipientForm";
import SigningPageSellerCounterOffer from "./pages/SigningPageSellerCounterOffer";
import SigningPageBuyerCounterOffer from "./pages/SigningPageBuyerCounterOffer";
import RedirectCounterOffer from "./pages/RedirectCounterOffer";
import { MLSDetails } from "./components/Recipients/MLSDetails";
import Settings from "./pages/Settings";
import InspectorDashboard from "./components/Inspector/InspectorDashboard";
import OfferRevision from "./components/Admin/Toolbar/Forms/OfferRevision";

import FAQs from "./pages/FAQs";
import AboutUs from "./pages/AboutUs";
import Blog from "./pages/Blog";
import SigningPageVaForm from "./pages/SigningPageVAForm";
import ThankYou from "./pages/ThankYou";
import ProtectedLandingPagesLayout from "./components/layout/ProtectedLandingPagesLayout";
import RenderHome from "./pages/RenderHome";
import PricePredictor from "./pages/PricePredictor";
import Financing from "./pages/Financing";
import ConfirmationCode from "./components/OTP/ConfirmationCode";


const routes = [
	{
		path: "app",
		element: <ProtectedRoute component={DashboardLayout} />,
		children: [
			{ path: "dashboard", element: <Dashboard /> },
			{
				path: "dashboard/admin/properties",
				element: <Properties />
			},
			{
				path: "dashboard/admin/revise-offer/:userPropertyId/:mlsId",
				element: <OfferRevision />
			},
			{
				path: "dashboard/inspector",
				element: <InspectorDashboard />
			}
		]
	},
	{
		path: "app",
		element: <ProtectedRoute component={ViewLayout} />,
		children: [
			{ path: "property-form/:mlsId", element: <PropertyForm /> },
			{ path: "property-details/:mlsId", element: <PropertyDetails /> },
			{ path: "document-e-signing", element: <SigningPage /> },
			{
				path: "buyer-property-offer-form/:mlsId/:userId/:id/:propertyId",
				element: <Form />
			},
			{
				path: "buyerCounterOffer/signing/:id",
				element: <SigningPageBuyerCounterOffer />
			}
		]
	},
	{
		path: "app",
		element: <ProtectedRoute component={ProtectedLandingPagesLayout} />,
		children: [
			{
				path: "home",
				element: <RenderHome />
			},
			{
				path: "about-us",
				element: <AboutUs />
			},
			{
				path: "blog",
				element: <Blog />
			},
			{
				path: "alokee-price-predictor",
				element: <PricePredictor />
			},
			{
				path: "financing",
				element: <Financing />
			},
			{
				path: "verify-otp",
				element: <ConfirmationCode />
			}
		]
	},
	{
		path: "/",
		element: <MainLayout />,
		children: [
			{ path: "home", element: <PublicRoute component={RenderHome} /> },
			{ path: "faqs", element: <PublicRoute component={FAQs} /> },
			{ path: "about-us", element: <PublicRoute component={AboutUs} /> },
			{ path: "blog", element: <PublicRoute component={Blog} /> },

			{ path: "login", element: <PublicRoute component={Login} /> },

			{ path: "edit-profile", element: <Settings /> },
			{
				path: "signup",
				element: <PublicRoute component={SignUp} />
			},
			{ path: "complete-profile", element: <CompleteProfile /> },
			{ path: "sellerForm/:mlsId/:userId/:id/:propertyId", element: <Form /> },
			{
				path: "signingPageForRecipient/:role/:code/:userPropertyId",
				element: <SigningPageOtherRecipient />
			},
			{
				path: "signingPageSellerCounterOffer/:role/:code/:userPropertyId",
				element: <SigningPageSellerCounterOffer />
			},
			{
				path: "redirecting",
				element: <Redirect />
			},
			{
				path: "recipientForm/:role/:code/:mlsId/:userPropertyId",
				element: <RecipientFrom />
			},
			{
				path: "MLSDetails/:role/:code/:mlsId/:userPropertyId",
				element: <MLSDetails />
			},
			{
				path: "redirectingRecipient",
				element: <RedirectRecipient />
			},
			{
				path: "redirectingCounterOffer",
				element: <RedirectCounterOffer />
			},
			{
				path: "counterOffer/signing/:id",
				element: <SigningPageBuyerCounterOffer />
			},
			{ path: "document-e-signing/VAForm", element: <SigningPageVaForm /> },

			{ path: "/", element: <Navigate to="/home" /> },
			{
				path: "thank-you",
				element: <PublicRoute component={ThankYou} />
			},
			{
				path: "alokee-price-predictor",
				element: <PublicRoute component={PricePredictor} />
			},
			{
				path: "financing",
				element: <PublicRoute component={Financing} />
			}
		]
	}
];

export default routes;
